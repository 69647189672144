body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  --fc-page-bg-color: #26251f;
  --fc-border-color: #ecc11f61;
  --fc-event-border-color: #000000;
}

/* Text of the date headers */
.fc .fc-col-header-cell-cushion {
    color: #ECC11F;
}
/* Text of the time rows */
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    color: #ECC11F;
}
/* The actual events */
.fc-timegrid-event .fc-event-main {
    background: #ECC11F;
    color: #000;
}
/* The little red line that indicates the current date/time */
.fc .fc-timegrid-now-indicator-container {
    overflow: visible;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: .5em;
}
